import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useGetTaskVariantsQuery } from '../../app/apiSliceProcessApi'
import { Loader } from '../../common/components'
import { Text, Toggle } from '../../common/designs'
import { useAppSelector, useQueryFilters } from '../../common/hooks'
import { generateIncrementalIndexesArrayUntil } from '../../common/utils/utils'
import EndNode from '../Processes/ProcessMining/Variants/EndNode'
import FlowChart from '../Processes/ProcessMining/Variants/FlowChart'
import StartNode from '../Processes/ProcessMining/Variants/StartNode'
import {
  configureRowHeightForVariantView,
  serverEdgesToChartEdges,
  serverStepFlowNodesToChartNodes,
} from '../Processes/ProcessMining/Variants/dataMappings'
import { NodeTypeEnum } from '../Processes/ProcessMining/Variants/enums'
import type { ServerNodeTypes } from '../Processes/ProcessMining/Variants/types'
import { VariantVisualizationLevelEnum } from '../Processes/ProcessMining/Variants/types'
import { useSelectedVariantsIndicies } from '../Processes/ProcessMining/Variants/useSelectedVariantsIndicies'
import { selectVariantOrder } from '../Processes/processesSlice'
import TaskFlowNode from './TaskFlowNode'
import TaskFlowNodeWithActivities from './TaskFlowNodeWithActivities'
import TaskVariantInsightBar from './TaskVariantInsightBar'
import { useGetTaskFiltersFromSearch } from './useGetTaskFiltersFromSearch'

const TaskVariants = () => {
  const { min_date, max_date } = useQueryFilters()
  const activeTaskFilters = useGetTaskFiltersFromSearch()
  const { taskName } = useParams()
  const variantOrder = useAppSelector(selectVariantOrder)
  const { windowLevelVariantIndices: selectedVariants, updateSelectedVariantIndicies } =
    useSelectedVariantsIndicies()
  const [isDetailedActivitiesActive, setIsDetailedActivitiesActive] = useState<boolean>(false)

  const { data, isFetching } = useGetTaskVariantsQuery({
    filters: { ...activeTaskFilters, min_date, max_date },
    task_name: taskName,
    selected_variants: selectedVariants,
    variant_order: variantOrder,
    is_task_path_visualization: isDetailedActivitiesActive,
  })

  useEffect(() => {
    // Prevent selectedVariants being more than data.variant_list length
    if (data && data.variant_list.length < selectedVariants.length) {
      updateSelectedVariantIndicies(
        generateIncrementalIndexesArrayUntil(data.variant_list.length),
        VariantVisualizationLevelEnum.WINDOW,
      )
    }
  }, [data]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedVariants.length !== 1) setIsDetailedActivitiesActive(false)
  }, [selectedVariants])

  const nodeTypes = useMemo(
    () => ({
      [NodeTypeEnum.NORMAL]: isDetailedActivitiesActive ? TaskFlowNodeWithActivities : TaskFlowNode,
      [NodeTypeEnum.START]: StartNode,
      [NodeTypeEnum.END]: EndNode,
    }),
    [isDetailedActivitiesActive],
  )

  const configureNodes = useCallback(
    (serverNodes: Array<ServerNodeTypes>) => {
      const rowHeightPx = configureRowHeightForVariantView(
        VariantVisualizationLevelEnum.WINDOW,
        isDetailedActivitiesActive,
        false,
        false,
        false,
      )
      return serverStepFlowNodesToChartNodes(serverNodes, rowHeightPx)
    },
    [isDetailedActivitiesActive],
  )

  if (isFetching) return <Loader />
  if (!data)
    return (
      <div className='flex w-screen items-center justify-center'>
        <Text variant='h4' size='xl'>
          No data available with specified filters.
        </Text>
      </div>
    )

  return (
    <div className='relative flex h-full'>
      <TaskVariantInsightBar data={data} selectedVariants={selectedVariants} />
      {selectedVariants.length === 1 && (
        <div className='absolute right-2 top-2 z-10'>
          <Toggle
            label='Show detailed activity flow'
            value={isDetailedActivitiesActive}
            onChange={setIsDetailedActivitiesActive}
          />
        </div>
      )}
      <FlowChart
        nodes={data.nodes}
        edges={data.edges}
        nodeTypes={nodeTypes}
        serverEdgesToChartEdges={serverEdgesToChartEdges}
        serverNodesToChartNodes={configureNodes}
      />
    </div>
  )
}

export default TaskVariants
