import {
  FilterDimemsionOptionData,
  FilterDimemsionOptionParams,
  FilterUserActionOptionData,
} from '../features/Processes/ProcessMining/Filters/types'
import {
  StateThroughputTimeData,
  StateThroughputTimeParams,
  StepAnalysisResult,
  StepGroupQueryParams,
  StepMetricQueryParams,
  StepMetricResult,
  SystemDistributionData,
  TaskFlowParams,
} from '../features/Processes/ProcessMining/Variants/NodeDataModal/types'
import {
  AutomationInsight,
  ServerMetricsInStep,
  ServerStateVariantData,
  ServerStepInsightsActivities,
  ServerStepVariantData,
  ServerTeamVariantData,
  StepActivityQueryOptions,
  StepProcessMiningOptions,
  StepVariantHighlightingOptions,
  TaskMiningOptions,
  TeamAndStateProcessMiningOptions,
  UserTask,
  WindowProcessMiningOptions,
} from '../features/Processes/ProcessMining/Variants/types'
import type {
  ActiveWorkTrendData,
  ConformanceByVariantMetrics,
  ConformanceMetrics,
  ConformanceQueryOptions,
  ConformanceTrendData,
  CopyPasteAnalysisQueryOptions,
  GroupBucketQueryParams,
  GroupQueryParams,
  InsightQueryOptions,
  MetricQueryParams,
  NormalDistributionData,
  NormalDistributionQueryOptions,
  ProcessIdQueryParamsBase,
  ServerInsightsData,
  StateNameQueryParams,
  TransactionGroupResult,
  TransactionGroupResultMultiple,
  TransactionLogData,
  TransactionLogQueryOptions,
  TransactionMetricResult,
  TransactionSearchQueryParams,
  TrendData,
  TrendQueryParams,
  UserActionQueryOptions,
  UserActivitiesData,
} from '../features/Processes/types'
import {
  PredefinedTaskAnalysisQueryOptions,
  PredefinedTaskAppUsageData,
  PredefinedTaskMetricData,
  PredefinedTaskSessionOrderData,
  TaskDiscoveryData,
  TaskFilterOptionParams,
  TaskListQueryOptions,
  TaskVariantData,
  TaskVariantOptions,
} from '../features/TaskDiscovery/types'
import { apiSlice } from './apiSlice'
import { WEB_APP_URL_PROCESS_API } from './configs'

export const apiSliceProcessApi = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    // **  TRANSACTIONS ** //
    getTransactionMetrics: build.query<TransactionMetricResult, MetricQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/metrics`,
        method: 'POST',
        body,
      }),
    }),
    getFilterDimensionOptions: build.query<
      FilterDimemsionOptionData[],
      FilterDimemsionOptionParams
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/dimension-options`,
        method: 'POST',
        body,
      }),
    }),
    getStateNames: build.query<string[], StateNameQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/state-names`,
        method: 'POST',
        body,
      }),
    }),
    getFilterUserActionOptions: build.query<FilterUserActionOptionData, ProcessIdQueryParamsBase>({
      query: ({ processId }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/user-action-options`,
        method: 'POST',
        body: { filters: {} },
      }),
    }),

    getTransactionGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/group`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionBucketGroup: build.query<TransactionGroupResult, GroupBucketQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/group/bucket`,
        method: 'POST',
        body,
      }),
    }),
    getSearchedTransactionId: build.query<string | null, TransactionSearchQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/transaction-by-name`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionStepGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/group/step`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionStepGroupMultiple: build.query<TransactionGroupResultMultiple, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/group/step/multiple`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionRelatedSystemGroup: build.query<TransactionGroupResult, GroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/group/related-system`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionTrends: build.query<TrendData[], TrendQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/trends`,
        method: 'POST',
        body,
      }),
    }),
    getTransactionActiveWorkTrend: build.query<ActiveWorkTrendData[], TrendQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/active-work-trend`,
        method: 'POST',
        body,
      }),
    }),
    getProcessUserActionAnalysis: build.query<UserActivitiesData[], UserActionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/user-actions`,
        method: 'POST',
        body,
      }),
    }),

    getTransactionLog: build.query<TransactionLogData[], TransactionLogQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/transaction-log`,
        method: 'POST',
        body,
      }),
    }),

    getNormalDistributionData: build.query<NormalDistributionData, NormalDistributionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/normal-distribution`,
        method: 'POST',
        body,
      }),
    }),
    getProcessCopyPasteAnalysis: build.query<UserActivitiesData[], CopyPasteAnalysisQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/transactions/${processId}/copy-pastes`,
        method: 'POST',
        body,
      }),
    }),

    // **  VARIANTS ** //
    getStepFlowChart: build.query<ServerStepVariantData, StepProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-variants`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantTeamData: build.query<
      { [key: string]: ServerMetricsInStep[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-variants/step-teams`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantWorkTypeData: build.query<
      { [key: string]: ServerMetricsInStep[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-variants/step-work-types`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getStepVariantAutomationInsightsData: build.query<
      { [key: string]: AutomationInsight[] },
      StepVariantHighlightingOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-variants/automation-insights`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),

    getTeamFlowChart: build.query<ServerTeamVariantData, TeamAndStateProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/session-variants`,
        method: 'POST',
        body,
      }),
    }),
    getStateFlowChart: build.query<ServerStateVariantData, TeamAndStateProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/state-change-variants`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getProcessMiningStepActivities: build.query<
      ServerStepInsightsActivities,
      StepActivityQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-activities`,
        method: 'POST',
        body,
      }),
    }),
    getProcessInsights: build.query<ServerInsightsData[], InsightQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/step-opportunities`,
        method: 'POST',
        body,
      }),
    }),
    getProcessConformanceMetrics: build.query<ConformanceMetrics, ConformanceQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/conformance`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getProcessConformanceTrends: build.query<ConformanceTrendData[], ConformanceQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/conformance-trends`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),
    getProcessConformanceMetricsByVariant: build.query<
      ConformanceByVariantMetrics[],
      ConformanceQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/variants/${processId}/conformance-by-variant`,
        method: 'POST',
        body,
      }),
      providesTags: ['ConformanceFlows'],
    }),

    // ** TASK MINING ** //
    getMultiSystemFlowTaskData: build.query<UserTask[], TaskMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-mining/${processId}/multi-system-tasks`,
        method: 'POST',
        body,
      }),
    }),

    // ** STEP ANALYSIS ** //
    getStepMetrics: build.query<StepMetricResult, StepMetricQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/metrics`,
        method: 'POST',
        body,
      }),
    }),
    getStepAnalysis: build.query<StepAnalysisResult, StepGroupQueryParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/analysis`,
        method: 'POST',
        body,
      }),
    }),
    getStepSystemDistribution: build.query<SystemDistributionData[], TaskFlowParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/system-distribution`,
        method: 'POST',
        body,
      }),
    }),
    getWindowFlowChart: build.query<TaskVariantData, WindowProcessMiningOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/variants`,
        method: 'POST',
        body,
      }),
    }),
    getStateThroughputTime: build.query<StateThroughputTimeData, StateThroughputTimeParams>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/state-throughput-time`,
        method: 'POST',
        body,
      }),
    }),
    getProcessStepCopyPasteAnalysis: build.query<
      UserActivitiesData[],
      CopyPasteAnalysisQueryOptions
    >({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/copy-pastes`,
        method: 'POST',
        body,
      }),
    }),
    getProcessStepUserActionAnalysis: build.query<UserActivitiesData[], UserActionQueryOptions>({
      query: ({ processId, ...body }) => ({
        url: `${WEB_APP_URL_PROCESS_API}/step-analysis/${processId}/user-actions`,
        method: 'POST',
        body,
      }),
    }),

    // ** TASK DISCOVERY ** //
    getTaskDiscoveryList: build.query<TaskDiscoveryData[], TaskListQueryOptions>({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/task-list`,
        method: 'POST',
        body,
      }),
    }),
    getTaskFilterOptions: build.query<string[], TaskFilterOptionParams>({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/task-filter-options`,
        method: 'POST',
        body,
      }),
    }),
    getTaskVariants: build.query<TaskVariantData, TaskVariantOptions>({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/task-variants`,
        method: 'POST',
        body,
      }),
    }),

    getPredefinedTaskMetrics: build.query<
      PredefinedTaskMetricData,
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/predefined-tasks/metrics`,
        method: 'POST',
        body,
      }),
    }),

    getPredefinedTaskAppUsage: build.query<
      PredefinedTaskAppUsageData[],
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/predefined-tasks/app-usage`,
        method: 'POST',
        body,
      }),
    }),

    getPredefinedTaskSessionOrdering: build.query<
      PredefinedTaskSessionOrderData[],
      PredefinedTaskAnalysisQueryOptions
    >({
      query: (body) => ({
        url: `${WEB_APP_URL_PROCESS_API}/task-discovery/predefined-tasks/session-ordering`,
        method: 'POST',
        body,
      }),
    }),

    // ** MONITORING ** //
    getProcessMonitorStatus: build.query<
      Array<{ process_name: string; total_duration_sec: number }>,
      void
    >({
      query: () => `${WEB_APP_URL_PROCESS_API}/transactions/tracked-processes`,
    }),
  }),
})

export const {
  // **  TRANSACTIONS ** //
  useGetTransactionMetricsQuery,
  useGetTransactionGroupQuery,
  useGetTransactionBucketGroupQuery,
  useLazyGetSearchedTransactionIdQuery,
  useGetTransactionStepGroupQuery,
  useGetTransactionStepGroupMultipleQuery,
  useLazyGetTransactionStepGroupMultipleQuery,
  useGetTransactionRelatedSystemGroupQuery,
  useGetTransactionTrendsQuery,
  useGetTransactionActiveWorkTrendQuery,
  useGetFilterDimensionOptionsQuery,
  useGetStateNamesQuery,
  useGetFilterUserActionOptionsQuery,
  useGetProcessUserActionAnalysisQuery,
  useGetTransactionLogQuery,
  useLazyGetTransactionLogQuery,
  useGetNormalDistributionDataQuery,
  useGetProcessCopyPasteAnalysisQuery,

  // **  VARIANTS ** //
  useGetStepFlowChartQuery,
  useGetStepVariantTeamDataQuery,
  useGetStepVariantWorkTypeDataQuery,
  useGetStepVariantAutomationInsightsDataQuery,
  useGetTeamFlowChartQuery,
  useGetStateFlowChartQuery,
  useGetProcessMiningStepActivitiesQuery,
  useGetProcessInsightsQuery,
  useGetProcessConformanceMetricsQuery,
  useGetProcessConformanceTrendsQuery,
  useGetProcessConformanceMetricsByVariantQuery,

  // ** TASK MINING ** //
  useGetMultiSystemFlowTaskDataQuery,

  // ** STEP ANALYSIS ** //
  useGetStepMetricsQuery,
  useGetStepAnalysisQuery,
  useGetStepSystemDistributionQuery,
  useGetWindowFlowChartQuery,
  useGetStateThroughputTimeQuery,
  useGetProcessStepCopyPasteAnalysisQuery,
  useGetProcessStepUserActionAnalysisQuery,

  // ** TASK DISCOVERY ** //
  useGetTaskDiscoveryListQuery,
  useLazyGetTaskFilterOptionsQuery,
  useGetTaskVariantsQuery,
  useGetPredefinedTaskMetricsQuery,
  useGetPredefinedTaskAppUsageQuery,
  useGetPredefinedTaskSessionOrderingQuery,

  // ** MONITORING ** //
  useGetProcessMonitorStatusQuery,
} = apiSliceProcessApi
